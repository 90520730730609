export const routes = {
    Chat: '/chatbots/chat.json?chatbot={chatbot}&message={message}',
    ClearChat: '/chatbots/chat/clear.json?chatbot={chatbot}',
    CreateChatbot: '/chatbots/create.json?title={title}&description={description}&instructions={instructions}',
    DeleteChatbot: '/chatbots/delete.json?id={id}',
    EditChatbot: '/chatbots/edit.json?chatbot={chatbot}&title={title}&description={description}&initialMessage={initialMessage}&model={model}&instructions={instructions}&logMessages={logMessages}&enabled={enabled}&primaryColor={primaryColor}&secondaryColor={secondaryColor}',
    Explanations: '/chatbots/explanations.json',
    GetChatbot: '/chatbots/get.json?chatbot={chatbot}',
    ListChatbots: '/chatbots/list.json',
    GetConversation: '/conversations/get.json?chatId={chatId}',
    ListConversations: '/conversations/list.json?chatbot={chatbot}',
    Language: '/language.json?text={text}',
    SetLanguage: '/language/set.json?language={language}',
    Ping: '/ping.json?pingMessage={pingMessage}',
    Import: '/tools/import.json?chatbot={chatbot}&website={website}&websites={websites}',
    ImportedLinks: '/tools/import/importedLinks.json?chatbot={chatbot}',
    Login: '/user/login.json?email={email}',
    Signup: '/user/signup.json',
    Verify: '/user/verify.json?email={email}&token={token}&pin={pin}',
    State: '/userstate/state.json',
    ChatPost: '/chatbots/chat.json',
    ClearChatPost: '/chatbots/chat/clear.json',
    CreateChatbotPost: '/chatbots/create.json',
    DeleteChatbotPost: '/chatbots/delete.json',
    EditChatbotPost: '/chatbots/edit.json',
    SetLanguagePost: '/language/set.json',
    PingPost: '/ping.json',
    ImportPost: '/tools/import.json',
    LoginPost: '/user/login.json',
    SignupPost: '/user/signup.json',
    VerifyPost: '/user/verify.json',
};
