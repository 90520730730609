import { Remove, ReplayRounded, Send } from '@mui/icons-material';
import { Box, Button, Chip, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ChatResponse from '../responses/Chat';
import { Chat2EndpointGetHook, Chat2EndpointGetPromise, Chat2EndpointPostPromise } from '../routes/Chat';
import { ClearChat2EndpointPostPromise } from '../routes/ClearChat';
import useEndpoint from './useEndpoint';
import Message from './Message';
import ThinkingMessage from './ThinkingMessage';
import useTranslationState from './useTranslationState';

interface Props {
    chatbot: string;
    isPreview?: boolean;
    isExternal?: boolean;
    update?: boolean;
}

function Chatbot(props: Props) {
    const translationState = useTranslationState({
        suggestions: '',
    });

    const [messagesState, setMessagesState] = useEndpoint<ChatResponse>(Chat2EndpointGetHook(props.chatbot, ''));

    const [messageState, setMessageState] = useState('');

    useEffect(() => {
        const promise = Chat2EndpointGetPromise(props.chatbot, '');

        promise.then((response) => {
            setMessagesState(response);
        });
    }, [props.update]);

    const sendMessage = (message: string) => {
        if (message.trim() === '') {
            return;
        }

        const promise = Chat2EndpointPostPromise(props.chatbot, message);

        const newMessages = messagesState.data.messages;
        newMessages.push({
            role: 'user',
            content: message,
        });

        setMessagesState({
            status: 200,
            data: {
                chatbot: messagesState.data.chatbot,
                messages: newMessages,
                suggestions: messagesState.data.suggestions,
            },
        });

        setSendingState(true);
        setMessageState('');

        promise.then((response) => {
            response.json().then((response: ChatResponse) => {
                setMessagesState(response);
                setSendingState(false);
            });
        });
    };

    const returnReverted = (messages: any[]) => {
        const reversedMessages = [...messages].reverse();

        return reversedMessages;
    };

    const [openState, setOpenState] = useState(false);

    useEffect(() => {
        window.parent.postMessage({
            frameHeight: openState ? '650px' : '50px'
        }, '*');
    }, [openState]);

    const clearChat = () => {
        const promise = ClearChat2EndpointPostPromise(props.chatbot);

        promise.then(() => {
            const promise = Chat2EndpointGetPromise(props.chatbot, '');

            promise.then((response) => {
                setMessagesState(response);
            });
        });
    };

    const [sendingState, setSendingState] = useState(false);

    const getPrimaryColor = () => {
        if (messagesState.status === 200) {
            return messagesState.data.chatbot.primaryColor;
        }

        return '#3277e5';
    };

    const getSecondaryColor = () => {
        if (messagesState.status === 200) {
            return messagesState.data.chatbot.secondaryColor;
        }

        return '#f5f5f5';
    };

    const [enabledState, setEnabledState] = useState(false);

    useEffect(() => {
        if (messagesState.status === 200) {
            setEnabledState(messagesState.data.chatbot.enabled);
        }
    }, [messagesState]);

    return (
        <>
            {messagesState.status === 200 && (enabledState || props.isPreview) &&
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: props.isExternal ? 0 : { xs: '70px', md: 0 },
                        right: { xs: 5, md: 25 },
                        width: { xs: 400, md: 450 },
                        maxWidth: '100vw',
                        height: openState ? 650 : 'auto',
                        maxHeight: props.isExternal ? '100vh' : '60vh',
                        display: 'flex',
                        borderRadius: '8px 8px 0 0',
                        backgroundColor: 'white',
                        flexDirection: 'column',
                        zIndex: 4,
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: getPrimaryColor(),
                            color: getSecondaryColor(),
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingX: 2,
                            borderRadius: '8px 8px 0 0',
                            borderBottom: openState ? '3px solid rgba(255, 255, 255, 0.5)' : null,
                        }}
                    >
                        <Typography
                            variant="h5"
                        >
                            {messagesState.status === 200 && messagesState.data.chatbot.title}
                            {props.isPreview && ' (Preview)'}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            {openState &&
                                <ReplayRounded
                                    onClick={clearChat}
                                    sx={{
                                        cursor: 'pointer',
                                        fontSize: 26,
                                    }}
                                />
                            }
                            <Remove
                                onClick={() => setOpenState(!openState)}
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: 26,
                                }}
                            />
                        </Box>
                    </Box>
                    {openState && (
                        <>
                            <Box
                                sx={{
                                    height: 'calc(100% - 50px)',
                                    width: '100%',
                                    backgroundColor: getSecondaryColor(),
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    overflowY: 'auto',
                                    scrollBehavior: 'smooth',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {messagesState.status === 200 && !sendingState && messagesState.data.suggestions.length > 0 &&
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                ml: 1,
                                                width: '100%',
                                                fontSize: 8,
                                                color: getPrimaryColor(),
                                            }}
                                        >
                                            {translationState.suggestions}
                                        </Typography>
                                    }
                                    {messagesState.status === 200 && !sendingState && messagesState.data.suggestions !== undefined && messagesState.data.suggestions.length > 0 && messagesState.data.suggestions.map((suggestion: string, index: number) => (
                                        <Chip
                                            key={index}
                                            label={suggestion}
                                            onClick={() => {
                                                setMessageState(suggestion);
                                                sendMessage(suggestion);
                                            }}
                                            sx={{
                                                margin: 1,
                                                backgroundColor: getPrimaryColor(),
                                                color: getSecondaryColor(),
                                                '&:hover': {
                                                    backgroundColor: getPrimaryColor(),
                                                },
                                            }}
                                        />
                                    ))}
                                </Box>
                                {sendingState && (
                                    <ThinkingMessage
                                        index={0}
                                        chatbotTitle={messagesState.data.chatbot.title}
                                        primaryColor={getPrimaryColor()}
                                        secondaryColor={getSecondaryColor()}
                                    />
                                )}
                                {messagesState.status === 200 &&
                                    returnReverted(messagesState.data.messages).map((message, index) => (
                                        <Message
                                            key={index}
                                            message={message}
                                            index={index}
                                            chatbotTitle={messagesState.data.chatbot.title}
                                            primaryColor={getPrimaryColor()}
                                            secondaryColor={getSecondaryColor()}
                                        />
                                    ))
                                }
                            </Box>
                            <Box
                                sx={{
                                    height: 'auto',
                                    display: 'flex',
                                    position: 'relative',
                                    p: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        position: 'relative',
                                        bottom: 0,
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            width: '100%',
                                            outline: '1px solid rgba(0, 0, 0, 0.5)',
                                            borderRadius: 4,
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: 4, // Set your desired borderRadius value here
                                                    border: 0,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                            },
                                            '.MuiInputBase-input': {
                                                width: '90%'
                                            },
                                        }}
                                        variant="outlined"
                                        value={messageState}
                                        onChange={(e) => setMessageState(e.target.value)}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter' || e.key === 'NumpadEnter' || e.key === 'Return') {
                                                if (!e.shiftKey) {
                                                    sendMessage(messageState);
                                                    e.preventDefault();
                                                }
                                            }
                                        }}
                                        multiline
                                        autoComplete="off"
                                    />
                                    <Button
                                        onClick={() => sendMessage(messageState)}
                                        variant="text"
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: 0,
                                            transform: 'translateY(-50%)',
                                            color: getPrimaryColor(),
                                        }}
                                    >
                                        <Send />
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            }
        </>
    );
}

export default Chatbot;
